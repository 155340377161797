.dashboard {
  .dashboard-section {
    margin-bottom: 0.5rem;
    display: flex;
    width: 100%;
    .latest {
      min-width: 240px;
      margin-right: 1.2rem;
      .ant-statistic-content-value {
        font-size: 1.8rem;
      }
    }
    .trender {
      flex: 1;
      .mq-charts {
        height: 300px;
      }
    }
  }
}