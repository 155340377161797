.feature-spec-table {
  pre {
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word,
  }

  .operations {
    button {
      margin-right: 0.5rem;
    }
  }
}