.model-spec-stats-card {
  .overview {
    .detail {
      display: flex;

      .prob-threshold-detail {
      }
    }
  }

  .spec-stats-detail-chart {
    height: 400px;
    width: 100%;
  }
}