.workdesk-layout {
  .side-menu {
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      align-items: center;
      .logo-icon {
        margin: 8px;
        width: 60px;
        g {
          fill: #fff;
        }
      }
    }
  }
}