@import 'app/variables.scss';

.stock-price-change {
  &.price-up {
    color: $up-color;
  }
  &.price-down {
    color: $down-color;
  }
  &.price-hold {
    color: $hold-color;
  }
}