.Welcome {
  display: flex;
  justify-content: center;
  align-items: center;

  .center-block {
    display: flex;
    flex-direction: column;

    .logo {
      width: 200px;
    }
  }
}