.ai-model-detail {
  .model-info {
    margin-bottom: 1rem;

    &>.ant-pro-card-body {
      gap: 1rem;
    }

    .ant-pro-card {
      height: 100%;
    }
  }
}