.account-detail {
  .account-info-item {
    display: flex;
    .label {
      margin-right: 1rem;
    }
  }
  .account-histories {
    .account-histories-chart {
      height: 400px;
    }
  }
}