.concept-sectors {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  .stock-classify-tag {
    margin-bottom: 2px;
  }
  button {
    float: right;
  }
}