.auth-page {
  display: flex;
  justify-content: center;
  align-items: center;
  .center-section {
    width: 400px;
  }
  .logo {
    width: 200px;
    position: fixed;
    top: 100px;
    margin-left: 100px;
  }
}