.cross-comparsion {
  .target-selector {
    .selector-options {
      margin-bottom: 6px;
    }
    .selector-status {
      display: flex;
      margin-bottom: 6px;
      div {
        margin-right: 8px;
      }
    }
    .target-items {
      border: 1px solid;
      border-radius: 2px;
      margin-right: 8px;
      height: 70vh;
      overflow: scroll;
      .range {
        display: flex;
        font-size: 16px;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        padding: 6px;
        &.selected {
          background-color: #91d5ff;
        }
        &:hover {
          background-color: #fafafa;
        }
        .range-item{
          margin-left: 6px;
        }
        .check-detail-btn {
        }
      }
    }
    .target-actions {
      margin: 8px 8px 0 0;
      .prev-group-btn {
        float: left;
      }
      .next-group-btn {
        float: right;
      }
    }
  }
}