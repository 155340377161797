.feature-store {
  .main-content {
    display: flex;
    gap: 1rem;
    min-height: 100%;

    .feature-category {
      // 30% of the screen
      flex: 0 0 15%;
    }
    .feature-specs-container {
      flex: 1;
      .ant-card-body {
        padding: 0;
      }
    }
  }
}