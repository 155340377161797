@import 'app/variables.scss';

.ai-model-predict-card {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 4px;
  width: 156px;
  cursor: pointer;
  &.unknown {
    background-color: #f5f5f5;
  }
  &.success {
    background-color: $up-color;
    color: #fff;
  }

  &.fail {
    background-color: $down-color;
    color: #fff;
  }

  &.inactive {
    background-color: gray !important;
    color: #000 !important;
  }
  .prob {
    font-size: 2rem;
    &.alert {
      color: red;
    }
  }
  .model-info {
    font-size: 0.8rem;
  }
  .predict-result {
    font-size: 0.8rem;
  }
}