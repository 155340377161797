@import '~antd/dist/reset.css';
@import 'app/variables.scss';


html {
  font-size: 16px;
}

.app {
  height: 100%;
}

.value-up {
  color: $up-color;
}

.value-down {
  color: $down-color;
}

.ant-statistic {
  &.value-up {
    .ant-statistic-content {
      color: $up-color;
    }
  }
  &.value-down {
    .ant-statistic-content {
      color: $down-color;
    }
  }
}

.row {
  display: flex;
}