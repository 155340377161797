@import 'app/variables.scss';
.tree-container {
  .tree-node-title {
    button {
      display: none;
    }
    &:hover {
      button {
        display: inline-block;
      }
    }
  }
  .ant-tree-node-selected {
    background-color: $primary-color !important;
    color: white;
  }
}