.signal-count-statistic {
  &>.ant-card-body {
    width: 100%;

    .signal-strategy-card {
      margin-bottom: 8px;
      width: 100%;

      .strategy-count {
        margin-right: 1rem;
        .count-wrapper {
          display: flex;
          align-items: center;
        }
      }

      .signal-classify-dist-charts {
        display: flex;

        .signal-classify-dist-pie-chart {
          width: 50%;
          height: 700px;
        }
      }
    }
  }
}