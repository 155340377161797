.feature-selector {
  display: flex;
  height: 600px;
  align-items: center;
  gap: 1rem;
  .candidate-tree, .current-tree {
    height: 100%;
    min-width: 400px;
    .ant-tree {
      overflow: auto;
      height: 100%;
    }
  }
  .operations {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}