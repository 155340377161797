.predict-mobile {
  .predicts {
    display: flex;
    flex-direction: column;
  }
  .predicts-classify-chart {
    width: 100%;
    height: 680px;
  }
  .predicts-limit-chart {
    width: 100%;
    height: 680px;
  }

  .predict-range-count-chart {
    width: 100%;
    height: 680px;
  }
}