.ai-model-spec-detail {
  .spec-info {
    margin-bottom: 1rem;
    &>.ant-pro-card-body {
      gap: 1rem;
    }
    .ant-pro-card {
      height: 100%;
    }
  }
}