.stock-limit-count-statistic {
  &>.ant-card-body {
    width: 100%;

    .strategy-count {
      margin-right: 1rem;
    }

    .stock-limit-classify-charts {
      display: flex;
      .stock-limit-classify-dist-chart {
        width: 100%;
        height: 700px;
      }
    }
  }
}