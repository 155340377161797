  .ai-modelpredict-aggregation-table {
    .predicts {
      display: flex;
      gap: 0.5rem;
    }
  }

  .ai-model-predicts {
    .predicts-classify-chart {
      width: 100%;
      height: 680px;
    }

    .predict-range-count-chart {
      width: 100%;
      height: 680px;
    }

    .predicts-limit-chart {
      width: 100%;
      height: 680px;
    }
  }