.signal-stock {
  padding: 16px;
  .candle-chart {
    height: 420px;
  }

  .fund-chart {
    height: 420px;
  }
  .stock-classifies {
    .classify-tags {
      margin-bottom: 8px;
      .stock-classify-tag {
        margin: 4px;
      }
    }
  }
}