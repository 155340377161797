.stock-daily-data {
  .ant-card-body {
    display: flex;

    .info-item {
      margin-right: 1.2rem;
    }

    .ant-statistic-content,
    .ant-statistic-content-value,
    .ant-statistic-content-value-decimal,
    .ant-statistic-content-suffix,
    .ant-statistic-content-prefix {
      font-size: 16px;
    }
  }

  .ant-card {
    margin-right: 1rem
  }
}