@import 'app/variables.scss';

.ths-top-list {
  &>.ant-card-body {
    display: flex;
    width: 100%;

    .ths-type {
      margin: 6px;
      flex: 1;
    }
  }
}