.classify-detail {
  .common-info {
    display: flex;
    .ant-card-body {
      display: flex;

      .info-item {
        margin-right: 1.2rem;
      }
      .ant-statistic-content,
      .ant-statistic-content-value, .ant-statistic-content-value-decimal, .ant-statistic-content-suffix, .ant-statistic-content-prefix {
        font-size: 16px;
      }
    }
    .ant-card {
      margin: 1rem 1rem 1rem 0;
    }

  }

  .tab-card {
    margin-bottom: 1rem;
  }

  .k-chart {
    height: 600px;
  }
}