.stock-dashboard {
  .stock-search-input {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      width: 500px;
    }
  }
}