@import 'app/variables.scss';

.ths-top-trender {
  .top-trender-card {
    font-size: 0.6rem;
    margin-bottom: 8px;
    .top-trender {
      display: flex;
      border: 1px solid #ddd;
      width: 100%;
      .top-trender-daily, .top-trender-rank {
        display: flex;
        flex-direction: column;
        border-left: 1px solid #ddd;
        text-align: center;
        &:first-child {
          border-left: none;
        }
        .header {
          background-color: #fafafa;
          height: 3rem;
          line-height: 3rem;
        }
        .item {
          height: 3rem;
          font-size: 0.8rem;
          border-top: 1px solid #ddd;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          flex-direction: column;
          .ant-tag {
            padding: 2px;
            font-size: 0.6rem;
            line-height: 0.8rem;
            margin: 0;
          }

          &.active {
            background-color: $primary-color;
            .ant-badge {
              color: #fff;
            }
          }

          &.watching {
            color: $up-color;
            .ant-badge {
              color: $up-color;
            }
          }

          .ant-badge, span {
            font-size: 0.6rem;
            .ant-badge-count {
              font-size: 0.6rem;
              line-height: 0.7rem;
              height: 0.7rem;
              min-width: 0.7rem;
            }
          }
        }
      }
      .top-trender-rank {
        width: 35px;
        border-right: 1px solid #ddd;
        .items {
          background-color: #fafafa;
        }
      }
      .content {
        display: flex;
        width: 100%;
        overflow-x: auto;
        .top-trender-daily {
          flex: 1;
        }
      }
    }
    .top-statistic {
      display: flex;
      .top-statistic-card {
        flex: 1;
        margin: 6px;
      }
    }
  }
}