@import 'app/variables.scss';

.candlechart-tooltip {
  min-width: 200px;
  .tooltip-date {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 4px;
  }
  .tooltip-series {
    .tooltip-series-marker {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      display: inline-block;
    }
    .tooltip-series-name {
      margin-left: 2px;
      display: inline-block;
    }
    .tooltip-series-value {
      float: right;
    }
    .tooltip-series-values {
      .value-item {
        margin-left: 12px;
        .value {
          float: right;
          &.up {
            color: $up-color;
          }
          &.down {
            color: $down-color;
          }
        }
      }
    }
  }
}