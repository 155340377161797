.login-form {
  min-width: 300px;
  .login-form-remember {

  }
  .login-form-forgot {
    float: right;
  }
  .login-form-button {
    width: 100%;
  }
}